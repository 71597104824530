import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import IconInvoice from '@svg/common/ic_invoice_alram.svg'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import {createLog} from '@util/logs'
import {isEmptyArray} from '@util/array'

interface IProps {
    className?: string
}

const InvoiceNotice: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {data: items} = useQueryGetPendingInvoices()

    const {goAccountInvoice} = useNavigation()

    if (isEmptyArray(items)) return null

    const onClickPayInvoice = () => {
        createLog('event', 'copyfolio_invoice_pay_click')
        goAccountInvoice()
    }

    return (
        <div
            className={`py-[15px] px-[20px] flex justify-between items-center ${className} rounded-[5px] border bg-red_tint02 dark:bg-dark_red_tint02 border-red dark:border-dark_red shadow-hover`}>
            <div className={'relative flex mr-[7px] w-[20px]'}>
                <IconInvoice className={'fill-gray01 dark:fill-dark_gray01'} />
            </div>
            <div className={'flex-1'}>
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01'}>
                    {t('copyfolio.invoice.unpaid.text', {count: items?.length ?? 0})}
                </Text>
            </div>

            <WizButton
                text={t('copyfolio.invoice.pay.text')}
                buttonType={`secondary`}
                size={'small'}
                onClick={onClickPayInvoice}
            />
        </div>
    )
}

export default InvoiceNotice
