import React, {useRef} from 'react'
import WizTable, {IWizTableColumn} from '@component/tables/WizTable/WizTable'
import TableHeader from '@component/tables/TableHeader'
import {useTranslation} from 'next-i18next'
import {IAsset} from '@type/profile'
import Text from '@component/Text/Text'
import LeverageTag from '@component/tags/LeverageTag'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import TokenLogo from '@component/images/TokenLogo'
import useView from '@hook/useView'
import EditAvgBuyPriceModal from '@feature/portfolio/components/EditAvgBuyPriceModal/EditAvgBuyPriceModal'
import useLogin from '@hook/useLogin'
import WizTipAutoOpen from '@component/tooltip/WizTipAutoOpen'
import Spinner from '@component/Spinner/Spinner'
import {createLog} from '@util/logs'
import AssetTypeTag from '@component/tags/AssetTypeTag'

interface IProps {
    data: IAsset[]
    isHide?: boolean
    className?: string
    hideTable?: boolean
    renderHeader?: () => React.ReactNode
    onClick?: () => void
    enableOnClick?: boolean
    hoverBorderClassName?: string
    user_id: string
    is_first_time?: boolean
    enableCellBgAnimation?: boolean
    status?: string
}

const AssetTable: React.FC<IProps> = ({
    data = [],
    className,
    onClick,
    enableOnClick = false,
    isHide = false,
    user_id,
    renderHeader,
    hideTable,
    hoverBorderClassName,
    is_first_time = false,
    enableCellBgAnimation = false,
    status,
}) => {
    const {t} = useTranslation()
    const {displayPrice, displayBalance, displayPercent, displayAmount} = useFormatNumber()
    const {renderBlur} = useView()
    const editAvgBuyPriceModalRef = useRef<any>()
    const {isMe} = useLogin(user_id)

    const renderEmpty = () => {
        if (status === 'paused') {
            return <></>
        }
        if (is_first_time) {
            return (
                <div className={'flex flex-col min-h-[84px] items-center justify-center space-x-[10px]'}>
                    <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                        {t('table.asset.firstLoading.text')}
                    </Text>
                    <Spinner visible={true} size={'small'} />
                </div>
            )
        } else {
            return (
                <div className={'flex min-h-[84px] items-center justify-center bg-white dark:bg-bg_dark_white01'}>
                    <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                        {t('table.asset.empty.text')}
                    </Text>
                </div>
            )
        }
    }

    const columns: IWizTableColumn<IAsset>[] = [
        {
            key: 'symbol',
            header: () => {
                return <TableHeader title={t('table.asset.column.symbol')} isSorted />
            },
            cell: item => {
                return (
                    <div className={'flex py-[7px] items-center'}>
                        <TokenLogo src={item?.logo} size={24} />
                        <div className={'ml-[7px]'}>
                            <div className={'flex items-center'}>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>{item?.symbol}</Text>
                                {item?.is_margin && (
                                    <LeverageTag
                                        className={'ml-[5px]'}
                                        leverage={item?.leverage}
                                        side={item?.side}
                                        cross_margin={item?.cross_margin}
                                    />
                                )}
                                {item?.wallet && <AssetTypeTag type={item?.wallet} />}
                            </div>
                            <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>{item?.name}</Text>
                        </div>
                    </div>
                )
            },
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'mobile_symbol',
            header: () => {
                return <TableHeader title={t('table.asset.column.symbol')} isSorted />
            },
            cell: item => {
                return (
                    <div className={'flex py-[7px] items-center'}>
                        <TokenLogo src={item?.logo} size={24} containerClassName={'sm:hidden mr-[7px]'} />
                        <div className={''}>
                            <div className={'flex items-center'}>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>{item?.symbol}</Text>
                            </div>
                            {item?.is_margin && (
                                <div className={'flex items-center'}>
                                    <LeverageTag
                                        leverage={item?.leverage}
                                        side={item?.side}
                                        cross_margin={item?.cross_margin}
                                    />
                                </div>
                            )}
                            {item?.wallet && <AssetTypeTag type={item?.wallet} />}
                        </div>
                    </div>
                )
            },
            visibilityClassName: 'hidden md:table-cell',
        },
        {
            key: 'current_price',
            header: () => {
                return <TableHeader title={t('table.asset.column.currentPrice')} isSorted align={'right'} />
            },
            cell: item => (
                <Text className={`text-body2 text-gray01 dark:text-dark_gray01 text-right ${renderBlur(isHide)}`}>
                    {displayPrice(item?.current_price, {showPreSign: true})}
                </Text>
            ),
            visibilityClassName: 'sm:hidden',
        },
        {
            key: 'avg_buy_price',
            header: () => {
                return <TableHeader title={t('table.asset.column.avgEntryPrice')} isSorted align={'right'} />
            },
            cell: item => {
                return (
                    <>
                        <div className={'flex justify-end items-center space-x-[5px]'}>
                            <Text
                                className={`text-body2 text-gray01 dark:text-dark_gray01 text-right ${renderBlur(
                                    isHide,
                                )}`}>
                                {displayPrice(item?.avg_buy_price, {showPreSign: true})}
                            </Text>
                            {isMe && !item?.is_margin && !item?.is_base && (
                                <Text
                                    onClick={e => {
                                        createLog('event', 'aseet_table_open_edit_avg_buy_price_modal', {
                                            key_id: item?.key_id,
                                            symbol: item?.symbol,
                                            avg_buy_price: item?.avg_buy_price,
                                        })
                                        e?.stopPropagation()
                                        editAvgBuyPriceModalRef?.current?.show(
                                            item?.key_id,
                                            item?.symbol,
                                            item?.avg_buy_price,
                                        )
                                    }}
                                    className={'text-body_comm text-gray03 dark:text-dark_gray03 cursor-pointer'}>
                                    {t('table.asset.column.avgEntryPrice.edit')}
                                </Text>
                            )}
                        </div>
                    </>
                )
            },
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'amount',
            header: () => {
                return <TableHeader title={t('table.asset.column.amount')} isSorted align={'right'} />
            },
            cell: item => {
                if (item?.is_position) {
                    return (
                        <Text
                            className={`text-body2 text-gray01 dark:text-dark_gray01 text-right ${renderBlur(isHide)}`}>
                            {item?.symbol === 'BTC' ? item?.amount : displayAmount(item?.amount, item?.current_price)}
                        </Text>
                    )
                }
                return (
                    <Text className={`text-body2 text-gray01 dark:text-dark_gray01 text-right ${renderBlur(isHide)}`}>
                        {item?.symbol === 'BTC' ? item?.amount : displayAmount(item?.amount, item?.current_price)}
                    </Text>
                )
            },
        },
        {
            key: 'invested_amount',
            header: () => {
                return <TableHeader title={t('table.asset.column.investedAmount')} isSorted align={'right'} />
            },
            cell: item => (
                <Text className={`text-body2 text-gray01 dark:text-dark_gray01 text-right ${renderBlur(isHide)}`}>
                    {displayBalance(item?.initial_value, {showPreSign: true, isAbsolute: true})}
                </Text>
            ),
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'profit',
            header: () => {
                return <TableHeader title={t('table.asset.column.profit')} isSorted align={'right'} />
            },
            cell: item =>
                item?.is_base === true ? (
                    <div
                        className={'flex justify-end'}
                        onClick={e => {
                            e.stopPropagation()
                        }}>
                        <WizTipAutoOpen
                            type={'assetTable_baseToken'}
                            title={t('portfolio.asset.baseToken.tooltip.title')}
                            contents={t('portfolio.asset.baseToken.tooltip.description')}
                            render={
                                <div className={'flex justify-end items-center px-[10px]'}>
                                    <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-right'}>-</Text>
                                </div>
                            }
                        />
                    </div>
                ) : (
                    <Text className={`text-body2 text-right ${getNumberColorClass(item?.pnl)} ${renderBlur(isHide)}`}>
                        {`${displayBalance(item?.pnl, {showPreSign: true})} (${displayPercent(item?.profit)})`}
                    </Text>
                ),
            visibilityClassName: 'md:hidden',
        },
        {
            key: 'mobile_profit',
            header: () => {
                return <TableHeader title={t('table.asset.column.profit')} isSorted align={'right'} />
            },
            cell: item =>
                item?.is_base === true ? (
                    <div
                        className={'flex justify-end'}
                        onClick={e => {
                            e.stopPropagation()
                        }}>
                        <WizTipAutoOpen
                            type={'assetTable_baseToken'}
                            title={t('portfolio.asset.baseToken.tooltip.title')}
                            contents={t('portfolio.asset.baseToken.tooltip.description')}
                            render={
                                <div className={'flex justify-end items-center px-[10px]'}>
                                    <Text className={'text-body2 text-gray01 dark:text-dark_gray01 text-right'}>-</Text>
                                </div>
                            }
                        />
                    </div>
                ) : (
                    <div className={'py-[7px]'}>
                        <Text
                            className={`text-body2 text-right ${getNumberColorClass(item?.pnl)} ${renderBlur(isHide)}`}>
                            {`${displayBalance(item?.pnl, {showPreSign: true})}`}
                        </Text>
                        <Text
                            className={`text-body2 text-right ${getNumberColorClass(item?.pnl)} ${renderBlur(isHide)}`}>
                            {`(${displayPercent(item?.profit)})`}
                        </Text>
                    </div>
                ),
            visibilityClassName: 'hidden md:table-cell',
        },
        {
            key: 'value',
            header: () => {
                return <TableHeader title={t('table.asset.column.value')} isSorted align={'right'} />
            },
            cell: item => (
                <Text
                    className={`text-ti2 text-right ${
                        item?.is_margin ? 'text-gray03 dark:text-dark_gray03' : 'text-gray01 dark:text-dark_gray01'
                    } ${renderBlur(isHide)}`}>
                    {displayBalance(Math.abs(item?.current_value), {showPreSign: true})}
                </Text>
            ),
            visibilityClassName: 'sm:hidden',
        },
    ]

    return (
        <div data-cy={'asset-table'}>
            <WizTable
                className={`border border-transparent02 dark:border-bg_dark_white01 shadow-normal rounded-[5px] overflow-hidden ${className}`}
                data={data ?? []}
                columns={columns}
                renderHeader={renderHeader}
                extractKey={item => `${item?.symbol}_${item?.extra}`}
                renderEmpty={renderEmpty}
                hoverBorderClassName={hoverBorderClassName}
                enableCellBgAnimation={enableCellBgAnimation}
                onClick={onClick}
                hideTable={hideTable}
                enableOnClick={enableOnClick}
                header={{
                    borderClassName: `${
                        renderHeader ? 'border-y' : 'border-b'
                    } border-transparent01 dark:border-bg_dark_white01`,
                    bgClassName: 'bg-gray09 dark:bg-bg_dark_white02',
                    paddingHorizontal: 20,
                }}
            />
            <EditAvgBuyPriceModal ref={editAvgBuyPriceModalRef} />
        </div>
    )
}

export default AssetTable
