import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import IconInvoice from '@svg/common/ic_invoice_alram.svg'
import {createLog} from '@util/logs'
import TagText from '@component/Text/TagText'
import Link from 'next/link'
import {getLinkUrl, URL_KEY_PORTFOLIO} from '@constant/url'
import Text from '@component/Text/Text'
import IconNext from '@svg/common/ic_btn_next.svg'
import useFormatDate from '@hook/useFormatDate'
import useQueryGetCopierMineBot from '@hook/query/portfolio/copyfolio/useQueryGetCopierMineBot'
import {isNotEmptyArray} from '@util/array'

interface IProps {
    className?: string
}

const InvoiceIncomingNotice: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {fromTodayToDate} = useFormatDate()
    const {data: data} = useQueryGetCopierMineBot()

    const incomingInvoiceList = useMemo(() => {
        const list = data?.bots
            ?.filter(item => item?.status === 'running' && item?.next_payment_amount > 0)
            ?.sort((a, b) => new Date(a?.next_payment_date)?.getTime() - new Date(b?.next_payment_date)?.getTime())

        return list
    }, [data])
    const leftDay = useMemo(() => {
        return fromTodayToDate(incomingInvoiceList?.at(0)?.next_payment_date)?.day
    }, [incomingInvoiceList])

    if (isNaN(leftDay) || leftDay > 2 || incomingInvoiceList?.length === 0 || isNotEmptyArray(data?.bots)) return null

    return (
        <div
            className={`py-[15px] px-[20px] flex justify-between items-center ${className} rounded-[5px] border bg-blue_tint02 dark:bg-dark_blue_tint02 border-blue dark:border-dark_blue shadow-hover`}>
            <div className={'relative flex mr-[7px] w-[20px]'}>
                <IconInvoice className={'fill-gray01 dark:fill-dark_gray01'} />
            </div>
            <div className={'flex-1'}>
                <TagText className={'text-body2 text-gray01 dark:text-dark_gray01'}>
                    {t('copyfolio.invoice.incoming.text', {count: incomingInvoiceList?.length})}
                </TagText>
            </div>
            {incomingInvoiceList?.length > 1 ? (
                <div className={'flex items-center gap-[7px]'}>
                    {leftDay > 0 ? (
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('copyfolio.invoice.incoming.date.days', {
                                day: leftDay,
                            })}
                        </Text>
                    ) : (
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('copyfolio.invoice.incoming.date.hour', {
                                hour: fromTodayToDate(incomingInvoiceList?.at(0)?.next_payment_date)?.hour,
                                min: fromTodayToDate(incomingInvoiceList?.at(0)?.next_payment_date)?.minute,
                            })}
                        </Text>
                    )}
                </div>
            ) : (
                <Link
                    href={getLinkUrl(URL_KEY_PORTFOLIO(incomingInvoiceList?.at(0)?.key_id))}
                    onClick={() => {
                        createLog('event', 'copyfolio_invoice_pay_click')
                    }}>
                    <div className={'flex items-center gap-[7px]'}>
                        {leftDay > 0 ? (
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('copyfolio.invoice.incoming.date.days', {
                                    day: leftDay,
                                })}
                            </Text>
                        ) : (
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('copyfolio.invoice.incoming.date.hour', {
                                    hour: fromTodayToDate(incomingInvoiceList?.at(0)?.next_payment_date)?.hour,
                                    min: fromTodayToDate(incomingInvoiceList?.at(0)?.next_payment_date)?.minute,
                                })}
                            </Text>
                        )}
                        <IconNext className={'w-[12px] h-[12px] fill-gray01 dark:fill-dark_gray01'} />
                    </div>
                </Link>
            )}
        </div>
    )
}

export default InvoiceIncomingNotice
